class CacheService {
  storage ?: Storage

  constructor() {
    this.storage = typeof window !== "undefined" ?  window?.sessionStorage : undefined;
  }

  setItem(key: string, value: any, expireMinutes : number) {
    const now = Date.now()
    const expireTime = now + expireMinutes * 60 * 1000

    const item = {
      value,
      expireTime
    }

    this.storage.setItem(key, JSON.stringify(item))
  }

  getItem(key: string) : any {
    const itemStr = this.storage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = Date.now()

    if (now > item.expireTime) {
      this.storage.removeItem(key)
      return null
    }

    return item.value
  }

  removeItem(key: string) {
    this.storage.removeItem(key)
  }

  clear() {
    this.storage.clear()
  }
}

const cachedService = new CacheService()

export default cachedService