import React, { useEffect, useState } from 'react'
import CurrencyInputField from 'react-currency-input-field'
import { CurrencyInputProps } from 'react-currency-input-field/src/components/CurrencyInputProps'
import { clearInput } from 'mp-structure/libs/utils'
import ClearInputButton from './ClearInputButton'

type Props = Omit<CurrencyInputProps, 'onChange'> & {
  readOnly ?: boolean,
  autoCompleteOn ?: boolean,
  onSet ?: (value: string | undefined) => void,
  error ?: string
}

const CurrencyInput = ({
  onSet,
  readOnly,
  disabled,
  className,
  error,
  prefix = '€',
  value,
  autoCompleteOn = false,
  ...rest
} : Props) => {
  const [inputValue, setInputValue] = useState(null)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className="pos-rel">
      <CurrencyInputField
        intlConfig={{ locale: 'en-US', currency: 'EUR' }}
        prefix={prefix}
        placeholder={rest?.placeholder || `${prefix}0.00`}
        disabled={disabled || readOnly}
        className={`${className || 'form-input w-100'}${error ? ' error' : ''}`}
        decimalsLimit={0}
        onValueChange={onSet}
        maxLength={7}
        max={9999}
        value={value}
        onFocus={autoCompleteOn ? null : (e) => e.target.setAttribute('autoComplete', 'off')}
        {...rest}
      />
      <ClearInputButton clearInput={() => clearInput(onSet, inputValue, setInputValue)} showClearInput={inputValue?.length > 0 && !(disabled || readOnly)} />
    </div>
  )
}

export default React.memo<Props>(CurrencyInput)
