import React, { useCallback, useMemo, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'
import DateContainer from 'mp-common/components/form/containers/DateContainer'
import 'moment/locale/en-gb'
import { isNaN, isNil } from 'lodash'
import GHref from 'mp-common/components/form/elements/GHref'
import moment from 'moment/moment'
import IconCalendar from '../../../../../icons/calendar.svg'

registerLocale('en-GB', enGB)

type Props = {
  readOnly ?: boolean,
  className ?: string,
  error ?: any,
  value ?: any,
  placeholder ?: any,
  onSet ?: any,
  showEasyFilters ?: boolean
}

const MultipleDatePicker = (props: Props) => {
  const {
    readOnly,
    className = 'form-input w-100',
    error,
    value,
    placeholder,
    onSet,
    showEasyFilters,
    ...rest
  } = props
  const ref = useRef<any>(null)

  const handleEnterKey = (event, val) => {
    const inputElements = document.querySelectorAll('input')
    const currentIndex = Array.from(inputElements).indexOf(event.target)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    ref.current?.setOpen(false)
    if (currentIndex !== -1 && currentIndex < inputElements.length - 1) {
      // Check if the current input element is found in the list of input elements
      // and if it's not the last input element in the list
      inputElements[currentIndex + 1]?.focus() // Focus on the next input element
    }

    if (val) {
      setTimeout(() => {
        onSet([moment(val).toISOString(), moment(val).toISOString()])
      }, 150)
    }
  }

  const handleArrowUpKey = (val) => {
    const date = moment(val && val !== '' ? val : new Date())
    date.subtract(1, 'weeks')
    onSet([date.toISOString(), date.toISOString()])
  }

  const handleArrowDownKey = (val) => {
    const date = moment(val && val !== '' ? val : new Date())
    date.add(1, 'weeks')
    onSet([date.toISOString(), date.toISOString()])
  }

  const handleArrowRightKey = (val) => {
    const date = moment(val && val !== '' ? val : new Date())
    date.add(1, 'days')
    onSet([date.toISOString(), date.toISOString()])
  }

  const handleArrowLeftKey = (val) => {
    const date = moment(val && val !== '' ? val : new Date())
    date.subtract(1, 'days')
    onSet([date.toISOString(), date.toISOString()])
  }

  const startDate = useMemo(() => {
    if (!isNil(value) && !isNaN(value)) {
      return typeof value[0] === 'string' ? new Date(value[0]) : value[0]
    }
  }, [value])

  const endDate = useMemo(() => {
    if (!isNil(value) && !isNaN(value)) {
      return typeof value[1] === 'string' ? new Date(value[1]) : value[1]
    }
  }, [value])

  const onKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
        // Call a specific function for the Enter key
        handleEnterKey(e, endDate || startDate)
        break
      case 'ArrowLeft':
        handleArrowLeftKey(endDate || startDate)
        break
      case 'ArrowRight':
        handleArrowRightKey(endDate || startDate)
        break
      case 'ArrowDown':
        // Call a specific function for the Arrow Down key
        handleArrowDownKey(endDate || startDate)
        break
      case 'ArrowUp':
        // Call a specific function for the Arrow Up key
        handleArrowUpKey(endDate || startDate)
        break
      default:
        break
    }

    e.preventDefault()
    return false
  }, [startDate, endDate, onSet])

  return (
    <div className="pos-rel">
      <DatePicker
        calendarContainer={
          showEasyFilters
            ? (props) => (
              <DateContainer
                {...props}
                setDate={(value) => {
                  onSet(value)
                  ref.current.setOpen(false)
                }}
              />
            )
            : undefined
        }
        isClearable={!readOnly}
        placeholderText={placeholder || 'DD/MM/YYYY'}
        dateFormat="dd/MM/yyyy"
        className={`date-picker ${className}${error ? ' error' : ''} `}
        onChange={onSet}
        readOnly={readOnly}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onKeyDown={onKeyDown}
        locale="en-GB"
        autoComplete="new-password"
        inline
        {...rest}
        ref={ref}
      />
      <GHref
        onClick={() => ref?.current?.setOpen(!ref?.current?.state?.open)}
        className="date-picker-calendar"
      >
        <IconCalendar />
      </GHref>
    </div>
  )
}

export default React.memo(MultipleDatePicker)
