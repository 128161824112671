import { useState } from 'react'
import { useToast } from 'mp-common/hooks/useToast'
import useTranslation from 'next-translate/useTranslation'
import { isNaN } from 'lodash'

const useInputFile = (props) => {
  const {
    next
  } = props
  const { t } = useTranslation('document')
  const { toastError } = useToast()
  const [submitting, setSubmitting] = useState(false)
  const [src, setSrc] = useState('')

  const submitForm = (file) => {
    setSubmitting(true)
    try {
      next(file)
    } catch (e) {
      return
    }

    setSubmitting(false)
  }

  const upload = (e) => {
    e.preventDefault()
    if (e.target.files.length === 0) {
      return
    }
    const file = e.target.files[0]
    const fileSizeInMB = (isNaN(file?.size) ? 0 : file.size) / (1024 * 1024)
    if (fileSizeInMB && fileSizeInMB <= 100) {
      submitForm(file)
      setSrc(URL.createObjectURL(file))
    } else {
      toastError(t('document_size_error'))
    }
  }

  return {
    submitting,
    upload,
    src
  }
}

export default useInputFile
