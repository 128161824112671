import React, { useMemo } from 'react'
import { imageAccessURL } from 'mp-structure/libs/api'

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  src : string,
  style?: React.CSSProperties,
  alt?: string,
  tip? : string,
  placeholder? : string
}

function GImage(props : Props) {
  const {
    src,
    style,
    alt,
    tip,
    placeholder,
    width,
    height,
    ...rest
  } = props

  const addDefaultImg = (e) => {
    if (placeholder) {
      e.target.src = `/img/${placeholder}`
    }
  }

  const source = useMemo(() => {
    if (src) {
      if (src?.includes('http')) {
        return src
      } if (src?.includes('/files/')) {
        return imageAccessURL + src
      }
      return `/img/${src}`
    } if (placeholder) {
      return `/img/${placeholder}`
    }
    return ''
  }, [src, placeholder, imageAccessURL])

  return (
    <img
      alt={alt}
      style={{ ...style, width: `${width}px`, height: `${height}px` }}
      data-tip={tip}
      onError={addDefaultImg}
      src={source}
      {...rest}
    />
  )
}

export default GImage
