import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { apiGet, getToken, setToken } from 'mp-structure/libs/api'
import { StateType } from 'mp-common/types/StateType'
import { dispatchLogout, dispatchUser } from 'mp-auth/stores/action'
import { dispatchCountryFlagEndpoint } from 'mp-common/stores/data/action'
import cachedService from 'mp-structure/libs/cache'

const useSession = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const user = useSelector((state: StateType) => state.auth.user)
  const isLogout = useSelector((state: StateType) => state.auth.isLogout)
  const setUser = (user) => dispatchUser(user, dispatch)
  const setLogout = (isLogout) => dispatchLogout(isLogout, dispatch)
  const setCountryFlagEndpoint = (endpoint) => dispatchCountryFlagEndpoint(endpoint, dispatch)

  const userAvatar = useMemo(() => (user?.avatar || 'placeholder-user-avatar.jpg'), [user?.avatar])

  const logout = async () => {
    localStorage.clear()
    sessionStorage.clear()
    await apiGet('/user/logout')
    setToken('')
    setUser(false)
    setLogout(true)
    router.replace('/login')
  }

  const fetchUser = async () => {
    if (getToken()) {
      const response = await apiGet('/user/info')
      if (response) {
        setUser(response)
        if (response?.redirectUrl) {
          router.replace(response.redirectUrl)
        }
        const flagUrl = response?.data?.countries?.flagDownloadUrl
        if (flagUrl) {
          setCountryFlagEndpoint(flagUrl)
        }
        cachedService.setItem('cached_user_info', response.data, 5)
      } else {
        logout()
      }
      return response
    }
  }

  const isShipper = useMemo(() => user?.roles?.includes('ROLE_SHIPPER'), [user?.roles])
  const isCarrier = useMemo(() => user?.roles?.includes('ROLE_CARRIER'), [user?.roles])
  const isAdmin = useMemo(() => user?.roles?.includes('ROLE_COMPANY_ADMIN'), [user?.roles])
  const isMyCompany = useCallback((company) => user?.company?.id === company?.id, [user?.company?.id])
  const isMe = useCallback((checkUser) => checkUser && user?.id === checkUser?.id, [user?.id])

  return {
    isShipper,
    isCarrier,
    isLogout,
    user,
    isMe,
    isMyCompany,
    setUser,
    logout,
    setLogout,
    fetchUser,
    isAdmin,
    userAvatar
  }
}

export default useSession
