import axios from 'axios'
import store from 'store'
import moment from 'moment'
import { dispatchLogout, dispatchUser } from 'mp-auth/stores/action'
import { getStoreInstance } from 'mp-common/stores/store'
import getProperty from 'lodash/get'

export const getToken = () => (store.get('_t') && store.get('_t') !== '' ? store.get('_t') : null)
export const setToken = (token:any) => store.set('_t', token)
export const removeToken = () => store.remove('_t')

export const defaultOptions = (isFile:boolean, sendToken = true) => {
  const headers:any = {
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
    Accept: isFile ? 'multipart/form-data' : 'application/json',
    'Accept-Language': 'en-US'
  }
  const token = getToken()
  if (token && sendToken) {
    headers['Authorization'] = `Bearer ${token}`
  }
  if (typeof localStorage !== 'undefined') {
    const tempToken = localStorage.getItem('temp_token')
    if (tempToken) {
      headers['X-AUTH-TOKEN'] = tempToken
    }
  }
  return { headers }
}

export const domainURL = process?.env?.API_URL?.replace('/api/v2', '')
export const baseURL = process?.env?.API_URL?.replace('/api/v1', '')
export const serviceBaseUrl = process?.env?.SERVICE_API_URL
export const locationBaseURL = process?.env?.LOCATION_API_URL

export const imageAccessURL = baseURL.substring(0, baseURL.length - 7)
export const defaultInstance = axios.create({ baseURL: process.env.API_URL, withCredentials: true })
export const serviceInstance = axios.create({ baseURL: serviceBaseUrl, withCredentials: true })
export const locationInstance = axios.create({ baseURL: locationBaseURL, withCredentials: true })

export const guestInstance = axios.create({ baseURL, withCredentials: true })

export const onFulfilled = (isFile: false, sendToken : boolean) => (config: any) => {
  config.headers = {
    ...defaultOptions(isFile, sendToken).headers,
    ...config.headers
  }
  return config
}
defaultInstance.interceptors.request.use(onFulfilled(false, true))
guestInstance.interceptors.request.use(onFulfilled(false, false))
serviceInstance.interceptors.request.use(onFulfilled(false, true))

function arrayToObject(array) {
  const result = {}
  array.forEach(item => {
    result[item.field] = item.error
  })
  return result
}

export const formatErrors = (errors:any) => {
  if (Array.isArray(errors)) {
    return { errors: arrayToObject(errors) }
  }
  if (typeof errors !== 'object') {
    return { error: errors }
  }
  const errorDetail = getProperty(errors, 'response.data.detail')
  if (errorDetail) {
    const itemsString = errorDetail.slice(1, -1).slice(0, -1)
    const itemsArray = itemsString.split('],[')

    const resultObject = itemsArray.reduce((acc:any, item:any) => {
      const parsedItem = JSON.parse(JSON.stringify(item))
      Object.assign(acc, parsedItem)
      return acc
    }, {})

    return { errors: resultObject }
  }

  return Object.entries(errors).reduce((acc:any, [key, value]) => {
    if (key.includes('.')) {
      const [parentKey, childKey] = key.split('.')
      if (parentKey && !getProperty(acc, parentKey)) {
        acc[parentKey] = {}
      } else if (parentKey && acc[parentKey] && childKey) {
        acc[parentKey][childKey] = value
      }
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}

const dOptions = {
  params: {}
}

export const apiGet = async (url:string, options = dOptions) => {
  try {
    const results = await defaultInstance.get(url, options)
    if (results?.data) {
      return results.data
    }
    return false
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }
    // @todo do something smart here
    return false
  }
}

export const apiPost = async (url:string, params:any, options = {}, isFile = false) => {
  try {
    const results = await defaultInstance.post(
      url,
      isFile ? params : { ...params },
      { ...defaultOptions(isFile), ...options }
    )
    if (results) {
      return results
    }
    return results
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }

    return formatErrors(ex)
  }
}

export const apiPut = async (url:string, params:any, options = {}) => {
  try {
    const results = await defaultInstance.put(url, { ...params }, options)
    if (results.data) {
      return results.data
    }
    return results
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }
    return formatErrors(ex)
  }
}

export const apiDelete = async (url:string, options = {}) => {
  try {
    const results = await defaultInstance.delete(url, options)
    if (results.data) {
      return results.data
    }
    return results.status >= 200 && results.status < 300
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }

    // @todo do something smart here
    return { error: ex }
  }
}

export const apiGuestGet = async (url:string, options = dOptions) => {
  try {
    const results = await guestInstance.get(url, options)
    if (results?.data) {
      return results.data
    }
    return false
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }
    if (error.response?.data) {
      return error.response?.data
    }

    // @todo do something smart here
    return false
  }
}

export const apiGuestPost = async (url:string, params:any, options = {}, isFile = false) => {
  try {
    const results = await guestInstance.post(
      url,
      isFile ? params : { ...params },
      { ...defaultOptions(isFile, false), ...options }
    )
    if (results) {
      return results
    }
    return results
  } catch (ex) {
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error?.response?.data?.details) {
      return formatErrors(error.response.data.details)
    }

    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }

    return formatErrors(ex)
  }
}

export const apiLogin = async (form:any) => {
  try {
    const response = await axios.post(
      `${process.env.API_URL}/user/login`,
      form,
      { withCredentials: true }
    )

    if (response?.data?.apiKey) {
      setToken(response.data.apiKey)
      // if any redirection needed
      // if (response.data.redirectUrl) {
      //   return { redirectUrl: response.data.redirectUrl }
      // }
      return true
    }
    return response
  } catch (ex) {
    const error = ex as any
    if (error.response.data.error) {
      return error.response.data
    }

    return error.response?.data ? formatErrors(error.response.data.errors) : false
  }
}

export const logoutUser = () => {
  const store = getStoreInstance()
  dispatchUser(false, getProperty(store, 'dispatch'))
  removeToken()
  dispatchLogout(true, getProperty(store, 'dispatch'))
}

export function serializeDate(date:Date) {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
}

defaultInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
    //  logoutUser()
    //  window.location.href = '/login'
    }
    if (error.response && error.response.status === 400 && error.response.data.violations) {
      const v = error.response.data.violations
      const e:any = {}
      v.forEach((element:any) => {
        if (element.message.indexOf('::') > -1) {
          const parts = element.message.split('::')
          e[parts[0].trim()] = parts[1].trim()
        } else if (element.propertyPath.indexOf('.') > -1) {
          const parts = element.propertyPath.split('.')
          if (!e[parts[0].trim()]) {
            e[parts[0].trim()] = {}
          }
          e[parts[0].trim()][parts[1].trim()] = element.message
        } else {
          e[element.propertyPath] = element.message
        }
      })
      return { errors: e }
    }
    if (error && getProperty(error, 'response.data.detail')) {
      return Promise.reject(error)
    }
    const _err = getProperty(error, 'response.data.error')
    if (_err) {
      return Promise.reject(getProperty(error, 'response.data'))
    }
    return Promise.reject(error)
  }
)
