import React, { useEffect, useMemo, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import enGb from 'date-fns/locale/en-GB'
import { every, isArray, isNaN, isNil, omit } from 'lodash'
import DateContainer from '../containers/DateContainer'
import 'moment/locale/en-gb'
import GHref from '../elements/GHref'
// import IconCalendar from '../../../../../icons/calendar.svg'

registerLocale('en-gb', enGb)

type ValueType = Date | null | undefined

type Props = {
  value?: ValueType | ValueType[];
  form : Record<string, any>,
  onSet ?: any,
  name : string,
  eClass?: string,
  className?: string,
  errors ?: Record<string, any>,
  error?: string,
  dateClass?: string,
  style?: React.CSSProperties,
  readOnly?: boolean,
  notClearable? : boolean,
  type ?: 'date' | 'time' | 'date-time' | 'datetime',
  minDate ?: Date | null | undefined,
  maxDate ?: Date | null | undefined,
  disabled ?: boolean,
  placeholder?: string,
  dateFormat?: string,
  showEasyFilters ?: boolean,
  showMonthYearPicker?: boolean,
  showMonthDropdown?: boolean,
  showYearDropdown?: boolean
}

function RangeDatePicker(props : Props) {
  const ref = useRef<any>(null)
  const {
    value,
    name,
    eClass,
    className,
    errors,
    error,
    dateClass,
    notClearable,
    readOnly,
    showEasyFilters,
    onSet,
    placeholder,
    showMonthDropdown = false,
    showYearDropdown = false,
    minDate,
    maxDate,
    ...rest
  } = props
  const getError = () => {
    if (errors && name) {
      return errors[name]
    }
    return error
  }

  const startDate = useMemo(() => {
    if (!isNil(value) && !isNaN(value)) {
      return typeof value[0] === 'string' ? new Date(value[0]) : value[0]
    }
  }, [value])

  const endDate = useMemo(() => {
    if (!isNil(value) && !isNaN(value)) {
      return typeof value[1] === 'string' ? new Date(value[1]) : value[1]
    }
  }, [value])

  useEffect(() => {
    const img = new Image()
    img.src = '/img/erase-tiny-button-h.svg'
  }, [])

  const renderInput = () => (
    <>
      <DatePicker
        ref={ref}
        isClearable={!readOnly && !notClearable}
        calendarContainer={(props) => (
          <DateContainer
            {...props}
            setDate={(value) => {
              onSet(name, value)
              if (ref?.current?.setOpen) {
                ref.current?.setOpen(false)
              }
            }}
            {...props}
            showEasyFilters={showEasyFilters}
          />
        )}
        placeholderText={placeholder || 'DD/MM/YYYY-DD/MM/YYYY'}
        dateFormat="dd/MM/yyyy"
        readOnly={readOnly}
        className={`${className || 'form-input w-100'}${getError() ? ' error' : ''} date-picker${readOnly ? ' disabled' : ''}`}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={(k) => {
          if (isArray(k) && every(k, x => x === null)) {
            onSet(null)
          } else {
            onSet(k)
          }
        }}
        onFocus={(e) => e.target.setAttribute('autoComplete', 'off')}
        onKeyDown={(e) => {
          e.preventDefault()
        }}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        locale="en-gb"
        minDate={minDate}
        maxDate={maxDate}
      >
      </DatePicker>
    </>
  )

  const divRest = useMemo(() => omit(rest, ['minDate', 'maxDate']), [rest])

  return (
    <div {...divRest} className={eClass}>
      <div className={`pos-rel ${dateClass || ''}`}>
        {renderInput()}
        <GHref
          className="date-picker-calendar"
          onClick={() => ref?.current?.setOpen(!ref?.current?.state?.open)}
        >
          {/* <IconCalendar /> */}
        </GHref>
      </div>
    </div>

  )
}

export default RangeDatePicker
