import React, { HTMLProps } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { INPUT_CHARACTER_LIMITS } from 'mp-structure/libs/constants'

export type TextInputProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value' | 'name'> & {
  onSet ?: (value: any) => void
  value ?: any
  error ?: string,
  name ?: string
}

const PhoneNumberInput = (props: TextInputProps) => {
  const {
    onSet,
    readOnly,
    className,
    value,
    error,
    ...rest
  } = props

  const onFormChange = (e) => {
    if (readOnly) {
      return
    }
    if (onSet) {
      onSet(e)
    }
  }

  return (
    <PhoneInput
      international
      className={'pt-0 pb-0 ' + className + (error ? ' error' : '') + ((rest?.disabled || readOnly) ? ' disabled' : '')}
      value={value}
      onChange={onFormChange}
      placeholder="+"
      readOnly={readOnly}
      maxLength={INPUT_CHARACTER_LIMITS.CONTACT_NO}
      error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
      {...rest}
    />
  )
}

export default PhoneNumberInput
