import { toast } from 'react-toastify'
import { ToastOptions, ToastPosition } from 'react-toastify/dist/types'

type ToastFunction = (message: string, position?: ToastPosition) => void

type ReturnType = {
  toastError: ToastFunction,
  toastInfo: ToastFunction,
  toastSuccess: ToastFunction,
  toast: typeof toast,
  toastHtml,
  dismissToast
}

export const useToast = () : ReturnType => {
  const toastError = (message: string) => {
    if (!message || message.length < 3) {
      return
    }
    toast.error(`${message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }

  const toastInfo = (message: string, position?: ToastPosition) => {
    if (!message || message.length < 3) {
      return
    }
    toast.info(`${message}`, {
      position: position || 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }

  const toastHtml = (message, options : ToastOptions = {}) => {
    toast(message, {
      position: 'bottom-right',
      autoClose: 25000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      ...options
    })
  }

  const toastSuccess = (message : string) => {
    if (!message || message.length < 3) {
      return
    }
    toast.success(`${message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }

  const dismissToast = () => toast.dismiss()

  return {
    toast,
    toastHtml,
    toastError,
    toastInfo,
    toastSuccess,
    dismissToast
  }
}
