import React from 'react'
import moment from 'moment'

type Props = {
  className?: string,
  children?: any,
  setDate: (props?: any) => void,
  showEasyFilters?:boolean
}
function DateContainer({ className, children, setDate, showEasyFilters }: Props) {
  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value

    // Handle different filter options based on the selected value
    switch (selectedValue) {
      case 'tillToday':
        setDate([moment().set('years', 2000).toDate(), new Date()])
        break
      case 'today':
        setDate([new Date(), null])
        break
      case 'thisWeek':
        setDate([moment().startOf('week').toDate(), moment().endOf('week').toDate()])
        break
      case 'thisMonth':
        setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
        break
      case 'last3Months':
        setDate([
          moment().subtract(3, 'months').toDate(),
          moment().toDate()
        ])
        break
      default:
        setDate([])
        break
    }
  }

  return (
    <div style={{ padding: '4px' }}>
      <div className={className}>
        {showEasyFilters && (
          <div style={{
            backgroundColor: 'rgba(240,240,240,0.82)',
            borderBottom: '1px solid #ccc',
            paddingBottom: 5,
            width: 212,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            <div style={{ fontSize: 13, padding: 3, fontWeight: 600 }}>Easy Filters
            </div>
            <div style={{ textAlign: 'center', fontSize: 10, fontWeight: 400, padding: 5 }}>
              You can select a predefined option from following list
            </div>
            <div className="d-flex w-100 row align-center justify-around">
              <select
                onChange={handleSelectChange}
              >
                <option value="">Select Easy Filter</option>
                <option value="tillToday">Till Today</option>
                <option value="today">Today</option>
                <option value="thisWeek">This Week</option>
                <option value="thisMonth">This Month</option>
                <option value="last3Months">Last 3 Months</option>
              </select>
            </div>
          </div>
        )}
        <div className="pos-rel">{children}</div>
      </div>
    </div>
  )
}
export default DateContainer
