import React from 'react'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  className ?: string,
  onClick: (e:any) => void | Promise<void|boolean>,
  label?: string,
  labelKey?: string,
  children?: React.ReactNode | React.ReactNode[],
  submitting?: boolean,
  disabled?: boolean,
  style?: React.CSSProperties,
}

function GButton(props : Props) {
  const {
    className = '',
    onClick,
    label,
    labelKey,
    children,
    submitting,
    disabled,
    ...rest
  } = props
  const { t } = useTranslation('common')

  return (
    <button
      disabled={submitting || disabled}
      className={className + ' btn' + (disabled || submitting ? ' disabled' : '') + (submitting ? ' submitting' : '')}
      onClick={disabled || onClick ? (e) => onClick(e) : () => null}
      type="button"
      {...rest}
    >
      {labelKey ? t(labelKey) : (label || children)}
      {submitting && (
        <span className="ml-10">
          <i className="fa fa-spinner fa-spin" />
        </span>
      )}
    </button>
  )
}

export default GButton
