import moment from 'moment-timezone'
import { isArray, isNumber, isObject, isString } from 'lodash'

/**
 * Handles the horizontal scroll function in tables.
 * @params type : scroll direction
 */
export const handleScroll = (type: string) => {
  const scrollElement = document.getElementsByClassName('tm-table-extra-columns-outer')[0]
  if (scrollElement) {
    const currentScroll = scrollElement?.scrollLeft
    let newScroll = 0
    if (type === 'left') {
      newScroll = currentScroll - 90 > 0 ? currentScroll - 90 : 0
    } else if (type === 'right') {
      newScroll = currentScroll + 90 < (scrollElement.scrollWidth - scrollElement.clientWidth) ? currentScroll + 90 : (scrollElement.scrollWidth - scrollElement.clientWidth)
    }

    const scrollableDivs = document.querySelectorAll('.tm-table-extra-columns-outer')

    scrollableDivs.forEach((_, index) => {
      scrollableDivs[index]?.scrollTo({
        top: 0,
        left: newScroll,
        behavior: 'smooth'
      })
    })
  }
}

/**
 * Converts a UTC date to the local timezone.
 * @param {string} utcDate - The UTC date in ISO format.
 * @returns {string} - The date formatted in the local timezone.
 */
export function convertToLocalTime(utcDate: string|Date|null, format ?: string) {
  if (!utcDate) {
    return ''
  }

  // Guess the user's current timezone
  const userTimeZone = moment.tz.guess()

  // Parse the UTC date and convert to the local timezone
  const localDate = moment(utcDate).tz(userTimeZone)

  // Return the date formatted in a more readable form
  return format === null ? localDate.toISOString() : localDate.format(format)
}

export function dateToDM(date?: Date|string) {
  return date ? convertToLocalTime(date, 'DD/MM') : ''
}

export function dateToDMY(date?: Date|string) {
  return date ? convertToLocalTime(date, 'DD/MM/YYYY') : ''
}

export function dateToDMYhhMM(date?: Date|string) {
  return date ? convertToLocalTime(date, 'DD/MM/YYYY HH:mm') : ''
}

export function dateRangeToDMYhhMM(date: Date[]|string[], withoutTime?: boolean, convertToDM?: boolean) {
  if (convertToDM) {
    return date[1] ? `${dateToDM(date[0])} - ${dateToDM(date[1])}` : dateToDM(date[0])
  }
  if (withoutTime) {
    return date[1] ? `${dateToDMY(date[0])} - ${dateToDMY(date[1])}` : dateToDMY(date[0])
  }
  return date[1] ? `${dateToDMYhhMM(date[0])} - ${dateToDMYhhMM(date[1])}` : dateToDMYhhMM(date[0])
}

export function dateToHM(date?: Date) {
  return date ? convertToLocalTime(date, 'HH:mm') : ''
}

export const copyToClipboard = (text?: string) => {
  if (text) {
    navigator.clipboard.writeText(text)
  }
}

export function errorsArrayToObject(array) {
  const result = {}
  array.forEach(item => {
    result[item.field] = item.error
  })
  return result
}

export function getCountryCodeFromNIF(nif:string) {
  return nif.substring(0, 2)
}

export function getNIFFromNIF(nif:string) {
  return nif?.substring(2)
}

export const parseDateForQuery = (date) => {
  try {
    return JSON.parse(decodeURIComponent(date as string))
  } catch (error) {
    return decodeURIComponent(date as string)
  }
}

export const parseLocation = (object: string) => {
  const location = JSON.parse(object)
  return ({
    lat: location.latitude,
    lng: location?.longitude
  })
}

export function removeNullArrayKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key]) && obj[key][0] === null) {
      // Skip the key if the array contains null values
      return acc
    } if (obj[key] === null || obj[key] === '') {
      // Skip the key if the value is null or an empty string
      return acc
    }
    acc[key] = obj[key]
    return acc
  }, {})
}

export const clearInput = (onSet, inputValue, setInputValue = (d) => null) => {
  if (onSet) {
    if (isString(inputValue)) {
      onSet('')
      setInputValue('')
    } else if (isNumber(inputValue) || isObject(inputValue)) {
      onSet(undefined)
      setInputValue(undefined)
    } else if (isArray(inputValue)) {
      onSet(undefined)
      setInputValue([])
    }
  }
}

export const parseViewOptions = (original, transformed) => {
  const transformedColumns = transformed.reduce((acc, item) => {
    // if (item.visible) {
    acc[item.category] = { columns: {} }
    acc[item.category].columns = item.columns.reduce((colAcc, col) => {
      colAcc[col.key] = col.visible
      return colAcc
    }, {})
    acc[item.category].visible = item.visible
    // }

    return acc
  }, {})

  const categoryOrder = Object.keys(transformedColumns).reduce((acc, category, index) => {
    acc[category] = index
    return acc
  }, {})

  return original.map(item => {
    if (transformedColumns[item.category]) {
      return {
        ...item,
        visible: transformedColumns[item.category].visible,
        columns: item.columns.map(col => ({
          ...col,
          visible: transformedColumns[item.category]?.columns[col.key] !== undefined ? transformedColumns[item.category]?.columns[col.key] : col.visible
        }))
      }
    }
    return item
  }).sort((a, b) => {
    const orderA = categoryOrder[a.category] !== undefined ? categoryOrder[a.category] : Infinity
    const orderB = categoryOrder[b.category] !== undefined ? categoryOrder[b.category] : Infinity
    return orderA - orderB
  })
}

export const formatTime = (time) => {
  const localDate = new Date()
  const hours = (time / 60) + ((localDate.getTimezoneOffset() * (-1)) / 60)

  const hour = Math.floor(hours % 24).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  const minutes = (time % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })

  return `${hour}:${minutes}`
}

// const getParameters = (subject, body) => {
//
// }

export function emailLink(email, subject, body) {
  return `mailto:${email}` + `?subject=${subject}` + `&body=${body}`
}
