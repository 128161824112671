import React, { useState } from 'react'
import styles from 'mp-common/components/form/styles/Password.module.scss'
import GImage from '../elements/GImage'

type PasswordInputPropsType = {
  name?: string,
  className?: string,
  error?: string,
  onSet: (v) => void,
  value: string,
  placeholder?: string
}

const PasswordInput = (props: PasswordInputPropsType) => {
  const {
    name,
    className,
    error,
    onSet,
    value,
    placeholder,
    ...rest
  } = props
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <div className="pos-rel">
      <input
        autoComplete="on"
        name={name}
        className={`${className || 'form-input w-100'}${error ? ' error ' : ''}`}
        value={value}
        type={(isPasswordVisible && value) ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={(e) => onSet(e.target.value)}
        {...rest}
      />
      {value && (
        <div className="input-icon">
          <GImage
            src={isPasswordVisible ? 'icon-eye.svg' : 'icon-eye-closed.svg'}
            className={styles['show-password-icon']}
            alt={isPasswordVisible ? 'Show Password' : 'Hide Password'}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </div>
      )}
    </div>
  )
}

export default React.memo(PasswordInput)
