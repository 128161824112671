import React, { useEffect } from 'react'

type Props = {
  clearInput?: (e) => void | Promise<void|boolean>
  showClearInput?: boolean
}

const ClearInputButton = (props : Props) => {
  const {
    clearInput,
    showClearInput
  } = props

  useEffect(() => {
    const img = new Image()
    img.src = '/img/erase-tiny-button-h.svg'
  }, [])

  return (
    <div className="input-close-icon-container">
      {showClearInput && <span className="input-close-icon" onClick={clearInput} />}
    </div>
  )
}

export default ClearInputButton
