import React, { useEffect, useState } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { clearInput } from 'mp-structure/libs/utils'
import { isNil } from 'lodash'
import ClearInputButton from './ClearInputButton'

type Props = Omit<NumberFormatProps, 'onChange' | 'allowDecimal'> & {
  readOnly ?: boolean;
  onSet ?: any,
  error ?: string,
  suffix ?: string,
  allowDecimal ?: boolean,
  autoCompleteOn ?: boolean,
  value ?: any
}

function NumberInput(props: Props) {
  const {
    onSet,
    readOnly,
    className,
    value,
    error,
    placeholder = null,
    allowDecimal = false,
    autoCompleteOn = false,
    suffix,
    ...rest
  } = props
  const [inputValue, setInputValue] = useState(null)

  const onFormChange = (event: any) => {
    let value: number|undefined
    if (allowDecimal) {
      value = parseFloat(event.value)
    } else if (!event.floatValue || `${event.floatValue}` === event.value) {
      value = event.floatValue
    }
    onSet(value)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className="pos-rel">
      <NumberFormat
        placeholder={placeholder || (allowDecimal ? '0.00' : '0')}
        className={`${className || 'form-input w-100'}${error ? ' error' : ''}${(readOnly ? ' disabled' : '')}`}
        onValueChange={onFormChange}
        value={value}
        disabled={readOnly}
        suffix={suffix}
        thousandSeparator=","
        decimalSeparator="."
        onFocus={autoCompleteOn ? null : (e) => e.target.setAttribute('autoComplete', 'off')}
        {...rest}
      />
      <ClearInputButton
        clearInput={() => clearInput(onSet, inputValue, setInputValue)}
        showClearInput={!!(inputValue && !isNil(inputValue) && !Number.isNaN(inputValue) && !(!!rest?.disabled || !!readOnly))}
      />
    </div>
  )
}

export default React.memo(NumberInput)
