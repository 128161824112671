import React, { useCallback, useMemo, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import enGb from 'date-fns/locale/en-GB'
import 'moment/locale/en-gb'
import GHref from 'mp-common/components/form/elements/GHref'

registerLocale('en-GB', enGb)

const SingleDatePicker = (props) => {
  const {
    readOnly,
    className = 'form-input w-100',
    error,
    value,
    placeholder,
    onSet,
    showMonthDropdown = false,
    showYearDropdown = false,
    ...rest
  } = props
  const ref = useRef<any>(null)

  const onKeyDown = useCallback((e) => e.preventDefault(), [])

  const _value = useMemo(() => {
    if (value) {
      return new Date(value as string)
    }
    return null
  }, [value])

  return (
    <div className="pos-rel">
      <DatePicker
        isClearable={!readOnly}
        placeholderText={placeholder || 'DD/MM/YYYY'}
        dateFormat="dd/MM/yyyy"
        selected={_value}
        onChange={onSet}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
        locale="en-GB"
        autocomplete="new-password"
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        onFocus={(e) => e.target.setAttribute('autoComplete', 'off')}
        {...rest}
        className={`date-picker ${className}${error ? ' error ' : ''}`}
      />
      <GHref
        onClick={() => ref?.current?.setOpen(!ref?.current?.state?.open)}
        className="date-picker-calendar"
      >
        {/* <IconCalendar /> */}
      </GHref>
    </div>
  )
}

export default React.memo(SingleDatePicker)
