import React from 'react'
import useInput from '../hooks/useInput'
import { GImage } from '../elements'

type Props = {
  className?: string
  labelClassName?: string
  label?: string
  labelFor?: string
  errors?: Record<string, any>
  form: Record<string, any>
  name: string
  required?: boolean
  disabled?: boolean
  onChange?: (key: string, value: any) => void
  renderInput: (param?: any) => JSX.Element
  style?: React.CSSProperties,
  info?: string,
  infoClass?: string
}

function InputLabeledContainer(props: Props) {
  const {
    className = '',
    labelClassName = 'form-label',
    label = '',
    labelFor,
    errors,
    form,
    name,
    required,
    disabled,
    onChange,
    renderInput,
    style,
    info,
    infoClass
  } = props

  const { errorContent, errorText, value, onSet } = useInput({
    errors,
    form,
    name,
    onChange
  })

  return (
    <div className={className} style={style || {}}>
      {label && (
        <label className={labelClassName} htmlFor={labelFor}>
          {' '}
          {label}
          {required && <span className="required">*</span>}
          {info && (
            <span className={`tm-tooltip-toggle${infoClass ? ` ${infoClass}` : ''}`} aria-label={info} tabIndex={0}>
              <GImage src="icon-info.svg" width={20} height={20} />
            </span>
          )}
          {errorContent}
        </label>
      )}
      {renderInput({ error: errorText, name, onSet, value, disabled, required })}
    </div>
  )
}

export default React.memo<Props>(InputLabeledContainer)
