import React, { useCallback, useMemo, useRef } from 'react'
import DatePickerField from 'react-datepicker'
import moment from 'moment'

const TimePicker = ({
  readOnly,
  className = 'form-input w-100 ',
  error,
  value,
  placeholder,
  onSet,
  clearable = true,
  initialValue = null,
  ...rest
}) => {
  const ref = useRef<any>()

  const isValueDifferent = useMemo(() => {
    let returnValue = false
    if (value) {
      const _value = moment(value)
      if (_value.hour() !== 8 && _value.hour() !== 17) {
        returnValue = true
      } else if (_value.minute() !== 0) {
        returnValue = true
      }
    }
    return returnValue
  }, [value])

  const handleArrowDownKey = () => {
    const val = initialValue || value
    const date = moment(val)
    date.add(15, 'minutes')
    onSet(date.toISOString())
  }

  const handleArrowUpKey = () => {
    const val = initialValue || value

    const date = moment(val)
    date.subtract(15, 'minutes')
    onSet(date.toISOString())
  }

  const handleEnterKey = (event) => {
    const inputElements = document.querySelectorAll('input,textarea')
    const currentIndex = Array.from(inputElements).indexOf(event.target)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current?.setOpen(false)
    if (currentIndex !== -1 && currentIndex < inputElements.length - 1) {
      // Check if the current input element is found in the list of input elements
      // and if it's not the last input element in the list
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputElements[currentIndex + 1].focus() // Focus on the next input element
      event.preventDefault() // Prevent the default Enter key behavior (e.g., form submission)
    }
  }

  const handleKeyPress = useCallback((event) => {
    switch (event.key) {
      case 'Enter':
        // Call a specific function for the Enter key
        handleEnterKey(event)
        break
      case 'ArrowDown':
        // Call a specific function for the Arrow Down key
        handleArrowDownKey()
        break
      case 'ArrowUp':
        // Call a specific function for the Arrow Up key
        handleArrowUpKey()
        break
      default:
        break
    }

    return false
  }, [initialValue, value])

  return (
    <DatePickerField
      isClearable={!readOnly && isValueDifferent}
      placeholderText={placeholder || 'HH:MM'}
      className={`cursor-pointer time-picker ${className || 'form-input w-100'}${error ? ' error ' : ''}`}
      selected={initialValue || value}
      onChange={onSet}
      readOnly={readOnly}
      showTimeSelect
      onKeyDown={handleKeyPress}
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption={placeholder}
      dateFormat="HH:mm"
      timeFormat="HH:mm"
      onFocus={(e) => e.target.setAttribute('autoComplete', 'off')}
      {...rest}
      ref={ref}
    />
  )
}

export default React.memo(TimePicker)
