import { HTMLProps, memo } from 'react'
import { SelectType } from 'mp-common/types/SelectType'
import Select, { components, SingleValueProps } from 'react-select'
import Flags from 'country-flag-icons/react/3x2'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import useCountries from 'mp-common/hooks/useCountries'
import useTranslation from 'next-translate/useTranslation'

/**
 * @mohi
 * @ayberk
 *
 * Create multiple components from this component for specific purposes; it is already complex and will get increasingly complex.
 */
type Props = Omit<HTMLProps<HTMLSelectElement>, 'onChange'> & {
  onSet?: (value : any) => void,
  customClassName?: string,
  isLoading?: boolean,
  isMulti?: boolean,
  placeholder?: any,
  required?: boolean
  error?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  isShortListView?: boolean,
  shouldShowCode?: boolean,
  value: string,
  items?: any[]
}

const CountrySelect = (props: Props) => {
  const { t } = useTranslation()
  const {
    onSet,
    customClassName,
    isLoading,
    placeholder = t('options:select'),
    required,
    error,
    disabled,
    readOnly,
    isShortListView,
    isMulti,
    shouldShowCode,
    value,
    items
  } = props
  const { vatCountries, countries } = useCountries()
  const options = items || (shouldShowCode ? vatCountries : countries)

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props
    const Flag = Flags[data.value as keyof typeof Flags]

    return (
      <div ref={innerRef} className="custom-row" {...innerProps}>
        <span className="custom-row-flag">{Flag ? <Flag style={{ width: 16, height: 14 }} /> : null}</span>
        <div className="custom-row-text">
          {data.label}
        </div>
      </div>
    )
  }

  const CustomIndicator = () => (<></>)

  const CustomSingleValue = ({
    children,
    ...props
  }: SingleValueProps<any>) => {
    const value = get(props, 'data.value') as string
    const Flag = Flags[value as keyof typeof Flags]

    return (
      <components.SingleValue {...props}>
        {Flag ? <Flag style={{ width: 16, height: 14 }} /> : null}
        <span className="country-select-input-text">
          {children}
        </span>
      </components.SingleValue>
    )
  }

  return (
    <Select
      components={{
        Option: CustomOption,
        IndicatorsContainer: CustomIndicator,
        SingleValue: CustomSingleValue
      }}
      options={options}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={!readOnly}
      // value={options?.find((o) => o.value === value) || null}
      value={options?.find((o) => (o.value) === value)}
      className={(
        'country-select-container'
        + (required ? ' required' : '')
        + (error ? ' error' : '')
        + (disabled ? ' disabled' : '')
        + (customClassName ? ' ' + customClassName : '')
        + (isShortListView ? ' short-list' : '')
      )}
      classNamePrefix="country-select"
      placeholder={placeholder}
      isDisabled={disabled || readOnly}
      onChange={e => {
        if (onSet) {
          if (Array.isArray(e)) {
            onSet(e?.map((item) => item.value))
          } else {
            onSet(get(e, 'value'))
          }
        }
      }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          ...(state.selectProps.menuIsOpen && { transform: 'rotate(180deg)' })
        })
      }}
    />
  )
}
export default memo(CountrySelect)
