import React, { useEffect, useState } from 'react'
import { GImage } from 'mp-common/components/form/elements'
import useInputFile from 'mp-common/components/form/hooks/useInputFile'

type Props = {
  url ?: string,
  params ?: Record<string, any>,
  userImgSrc ?: string
}

const AvatarInput = (props : Props) => {
  const {
    params,
    url,
    userImgSrc
  } = props
  const [imageSrc, setImageSrc] = useState('')

  const {
    upload
  } = useInputFile({
    params,
    url,
    next: (v) => setImageSrc(v)
  })
  const fileRef = React.createRef<HTMLInputElement>()

  useEffect(() => {
    setImageSrc(userImgSrc || '')
  }, [userImgSrc])

  return (
    <>
      <input
        ref={fileRef}
        hidden
        type="file"
        accept="image/*"
        onChange={(e) => upload(e)}
        className="add-collection-img "
      />
      <GImage src={imageSrc} placeholder="placeholder-user-avatar.jpg" alt="Uploaded" className="round-image" />
    </>
  )
}

export default AvatarInput
