import { memo, useEffect, useState } from 'react'

function TextArea(props: any) {
  const {
    onSet,
    disabled,
    readOnly,
    className,
    value,
    error,
    name,
    eClass,
    placeholder,
    autoCompleteOn = false
  } = props
  const [inputValue, setInputValue] = useState('')

  const onFormChange = (e: any) => {
    if (readOnly) {
      return
    }
    if (onSet) {
      onSet(e.target?.value)
      setInputValue(e.target?.value)
    }
  }

  useEffect(() => {
    if (value) {
      setInputValue(value)
    } else {
      setInputValue('')
    }
  }, [value])

  return (
    <div className={'pos-rel' + (eClass ? ` ${eClass}` : '')}>
      <textarea
        name={name}
        disabled={!!disabled}
        readOnly={!!readOnly}
        className={`${className || 'form-textarea w-100'}${error ? ' error' : ''}${disabled ? ' disabled' : ''}`}
        value={inputValue}
        onChange={onFormChange}
        onFocus={autoCompleteOn ? null : (e) => e.target.setAttribute('autoComplete', 'off')}
        placeholder={placeholder}
      >
        {inputValue}
      </textarea>
    </div>
  )
}

export default memo(TextArea)
