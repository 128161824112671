import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { get } from 'lodash'
import { ErrorContent } from '../components/ErrorContent'

export type DefaultInputProps = {
  name?: string,
  form?: Record<string, any>,
  error?: string,
  errors?: Record<string, any>,
  onChange?: (key: string, value: string) => void,
}

const useInput = (props: DefaultInputProps) => {
  const {
    name,
    form,
    error,
    errors,
    onChange
  } = props
  const { t } = useTranslation()

  const value = form && name ? ((typeof get(form, name) === 'undefined' || get(form, name) === null) ? '' : typeof form[name] === 'boolean' ? (get(form, name) ? 1 : 0) : get(form, [name])) : undefined

  const errorText = useMemo(() => {
    if (error) {
      return error
    }
    if (errors && name && get(errors, name)) {
      return typeof get(errors, name) === 'string' && (get(errors, name) as string).includes('common:') ? t(get(errors, name) as string) : get(errors, name) as string
    }
    return null
  }, [error, JSON.stringify(errors), name])

  const errorContent = useMemo(() => {
    if (errorText && errorText !== '') {
      return <ErrorContent name={name || ''} errorText={errorText} />
    }
    return null
  }, [errorText, name])

  const onSet = (value?: any) => {
    if (onChange && name) {
      onChange(name, value)
    }
  }

  return {
    value,
    errorText,
    errorContent,
    onSet
  }
}

export default useInput
