export const INPUT_CHARACTER_LIMITS = {
  PLATES: 225,
  VAT_ID: 12,
  INVOICE_NO: 25,
  SALES_INVOICE_NO: 12,
  CREDIT_NOTE_NO: 12,
  ORDER_NO: 8,
  CUSTOMER_REF_NO: 100,
  CUSTOMER_REF_NO_COMBINED: 100,
  CONTACT_NO: 20,
  EMAIL: 64,
  POSTAL_CODE: 10,
  PRICE: 10,
  COMPANY_NAME: 128,
  USER_NAME: 64,
  LOCATION: 128,
  INTERNAL_COMMENT: 512,
  ADDITIONAL_INFO: 512
}

export const NumberCollection = Array.from({ length: 100 }, (_, i) => ({ key: i + 1, value: i + 1 }))

export const defaultPagination = {
  page: 1,
  perPage: 10,
  total: 10
}
