import React, { useCallback } from 'react'
import { SelectType } from 'mp-common/types/SelectType'

type CheckBoxMultipleProps = {
  value?: any,
  onSet ?: (value: boolean) => void,
  error ?: string,
  labelClass ?: string,
  eClass ?: string,
  readOnly ?: boolean,
  items: SelectType[]
  flow?: string
}

const CheckBoxMultiple = (props : CheckBoxMultipleProps) => {
  const {
    value,
    onSet,
    items,
    readOnly,
    error,
    labelClass,
    eClass,
    flow = 'row',
    ...rest
  } = props

  const onFormChange = (v) => {
    const _val = value || []
    const checked = _val.indexOf(v) > -1
    if (!checked) {
      _val.push(v)
    } else {
      const ind = _val.indexOf(v)
      _val.splice(ind, 1)
    }
    if (onSet) {
      onSet(_val)
    }
    return false
  }

  const checked = useCallback((v) => value.indexOf(v) > -1, [value])

  return (
    <div className={`d-flex ${flow}`}>
      {items && items.map((item) => (
        <div key={item.label} className={eClass || 'd-flex align-center mr-8 mt-4'}>
          <input
            type="checkbox"
            onChange={() => onFormChange(item.value)}
            disabled={!!readOnly}
            checked={checked(item.value)}
            className={`form-checkbox flex-none${error ? ' error' : ''}`}
            {...rest}
          />
          <label
            onClick={() => onFormChange(item.value)}
            className={labelClass + ' form-label mb-0'}
          >
            {item.label}
          </label>
        </div>
      ))}
    </div>
  )
}

export default CheckBoxMultiple
