import React, { HTMLProps, useCallback, useEffect, useRef, useState } from 'react'
import { get, isArray } from 'lodash'
import { clearInput } from 'mp-structure/libs/utils'
import ClearInputButton from './ClearInputButton'

export type TextInputProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value' | 'form'> & {
  onSet?: (value: any) => void,
  value?: string,
  error?: string,
  name?: string,
  defaultValue?: string,
  eClass?: string,
  autoCompleteOn?: boolean
}

function TextInput(props: TextInputProps) {
  const {
    onSet,
    required,
    disabled,
    readOnly,
    className,
    value,
    error,
    autoCompleteOn,
    defaultValue,
    name,
    eClass,
    ...rest
  } = props
  const [inputValue, setInputValue] = useState<any>()
  const [cursor, setCursor] = useState(null)
  const ref = useRef(null)
  const inputType = rest.type || 'text'

  const onFormChange = (e:any) => {
    if (readOnly || disabled) {
      return
    }
    if (onSet) {
      setCursor(e.target.selectionStart)
      onSet(e.target?.value)
      setInputValue(e.target?.value)
    }
  }

  const handleClearInput = useCallback(() => clearInput(onSet, inputValue[0], setInputValue), [onSet, inputValue, setInputValue])

  useEffect(() => {
    if (value) {
      setInputValue([value])
    } else if (defaultValue) {
      setInputValue([defaultValue])
    } else {
      setInputValue('')
    }
  }, [value, defaultValue])

  useEffect(() => {
    const input:any = ref.current
    if (input && inputType === 'text') {
      input?.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, inputType, inputValue])

  return (
    <div className={`pos-rel${eClass ? ` ${eClass}` : ''}`}>
      <input
        ref={ref}
        disabled={!!disabled || !!readOnly}
        required={!!required}
        readOnly={!!readOnly}
        className={`${className || 'form-input w-100'}${error ? ' error' : ''}`}
        value={get(inputValue, 'length') > 0 ? isArray(inputValue) ? inputValue[0] : inputValue : ''}
        onChange={onFormChange}
        maxLength={255}
        autoComplete={autoCompleteOn ? 'on' : 'new-password'}
        onFocus={autoCompleteOn ? null : (e) => e.target.setAttribute('autoComplete', 'off')}
        type={inputType}
        name={name}
        {...rest}
      />
      <ClearInputButton clearInput={handleClearInput} showClearInput={get(inputValue, 'length') > 0 && !(!!disabled || !!readOnly)} />
    </div>
  )
}

export default React.memo(TextInput)
