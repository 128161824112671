import React from 'react'
import { GHref, GImage } from 'mp-common/components/form/elements'
import useInputFile from 'mp-common/components/form/hooks/useInputFile'

type Props = {
  url ?: string,
  buttonClass ?: string,
  className ?: string,
  id ?: string | number,
  next ?: (data : Record<string, any>) => void
  children?: React.ReactNode | React.ReactNode[],
  params ?: Record<string, any>,
  label: string,
  required?: boolean,
  disabled?: boolean,
  icon: string,
  info?: string
}

const FileInput = (props : Props) => {
  const {
    params,
    children,
    url,
    buttonClass,
    id,
    next,
    label,
    required,
    icon,
    className,
    disabled
  } = props

  const {
    submitting,
    upload
  } = useInputFile({
    params,
    url,
    next
  })
  const fileRef = React.createRef<any>()

  const extensions = [
    'application/eml',
    'image/*',
    'application/pdf',
    'text/plain',
    'application/vnd.ms-outlook',
    'application/doc',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <>
      <GHref
        className={buttonClass}
        disabled={submitting || disabled}
        onClick={
          submitting
            ? () => null
            : () => fileRef.current.click()
        }
      >
        <div className="file-upload__input" />
        <div className={className}>
          <GImage
            src={icon}
            width={16}
            height={16}
          />
          {label}
          {required && <span>*</span>}
        </div>
        {children}
      </GHref>
      <input
        hidden
        ref={fileRef}
        id={`file${id}`}
        type="file"
        onChange={(e) => upload(e)}
        onClick={onInputClick}
        accept={extensions.join(',')}
        className="add-collection-img"
      />
    </>
  )
}

export default FileInput
