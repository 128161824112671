import React from 'react'
import useInputFile from 'mp-common/components/form/hooks/useInputFile'
import GImage from '../elements/GImage'

type Props = {
  placeholderImg: string,
  next ?: (data : Record<string, any>) => void,
  url ?: string,
}
const extensions = [
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
  'image/png'
]

const ImageInput = (props:Props) => {
  const { placeholderImg, next, url } = props
  const fileRef = React.createRef<HTMLInputElement>()
  const {
    upload,
    src
  } = useInputFile({
    url,
    next
  })

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  return (
    <div className="tm-onboarding-user-img">
      <GImage src={src || placeholderImg} alt="" width={100} height={100} style={{ borderRadius: '50%', height: '100px', objectFit: 'cover' }} />
      <div className="file-upload">
        <label className="file-upload__label">
          <GImage src="icon-camera.svg" />
        </label>
        <input
          ref={fileRef}
          type="file"
          className="file-upload__input cursor-pointer"
          accept={extensions.join(',')}
          onChange={(e) => upload(e)}
          onClick={onInputClick}
        />
      </div>
    </div>
  )
}

export default ImageInput
