import React, { RefObject, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

type Props<OnClickResponseType> = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'onClick'> & {
  href?: string,
  submitting?: boolean,
  onClick?: () => OnClickResponseType | void | Promise<void>,
  children?: React.ReactNode | React.ReactNode[],
  title ?: string,
  style?: React.CSSProperties,
  disabled?: boolean,
  disableHint ?: string,
  className?: string,
  tip?: string
  hrefRef?: RefObject<HTMLAnchorElement>
}

const GHref = <OnClickType, >(props : Props<OnClickType>) => {
  const {
    href,
    submitting,
    onClick,
    children,
    title,
    className = '',
    style = {},
    disabled,
    disableHint,
    tip,
    hrefRef,
    ...rest
  } = props

  const customOnClick = (e: any) => {
    e?.preventDefault()
    if (onClick && !disabled && !submitting) {
      onClick()
    }
  }

  useEffect(() => {
    if (tip) {
      ReactTooltip.rebuild()
    }
  }, [])

  return (href
    ? (
      <a
        className={`cursor-pointer ${className}${disabled ? ' disabled link-disabled' : ''}`}
        href={disabled ? '#' : href}
        style={style}
        data-tip={tip}
        title={title || ''}
        ref={hrefRef}
        {...rest}
      >
        {children}
      </a>
    ) : (
      <a
        className={`${className || ''}${disabled ? ' disabled link-disabled cursor-not-allowed' : ' cursor-pointer'}`}
        style={style}
        onClick={customOnClick}
        data-tip={tip}
        title={disabled ? disableHint : (title || '')}
        ref={hrefRef}
        {...rest}
      >
        {children}
        {submitting && (
          <span className="ml-10">
            <i className="fa fa-spinner fa-spin" />
          </span>
        )}
      </a>
    )
  )
}

export default GHref
