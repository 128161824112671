import ReactTooltip from 'react-tooltip'

type Props = {
  name: string,
  errorText: string
}

export function ErrorContent({ name, errorText }: Props) {
  return (
    <span className="error-input d-block ml-4">
      <i data-tip data-for={name} className="fa fa-circle-exclamation fa-lg text-color-red"></i>
      <ReactTooltip id={name} type="error">
        {errorText}
      </ReactTooltip>
    </span>
  )
}
