import React, { HTMLProps, useMemo } from 'react'
import get from 'lodash/get'
import useTranslation from 'next-translate/useTranslation'
import { isNil, isEmpty } from 'lodash'
import { TruckTypeResponse } from 'mp-common/types/TruckType'

export type SelectType = {
  id ?: string | number
  value: string | number
  label: string
}

export type GroupType = {
  label: string
  items: SelectType[] | TruckTypeResponse[]
}
type Value = string | number
type ObjectValue = { id?: Value }
type Props = Omit<HTMLProps<HTMLSelectElement>, 'onChange' | 'form' | 'defaultelement'> & {
  onSet: (value: any) => void,
  readOnly?: boolean,
  value?: Value | ObjectValue,
  items?: SelectType[],
  groups?: GroupType[],
  defaultItem?: SelectType,
  error?: string,
  loading?: boolean,
  placeholder?: string
  customLabelKey?: string
  customValueKey?: string
}

function SelectInput(props: Props) {
  const {
    onSet,
    className,
    error,
    items,
    value,
    multiple,
    readOnly,
    groups,
    placeholder,
    defaultItem = { label: 'Select', value: 'false' },
    loading,
    customLabelKey,
    customValueKey,
    ...rest
  } = props
  const { t } = useTranslation()

  const val = useMemo(() => ((typeof value === 'object' && value?.id) ? value.id : (typeof value === 'undefined' ? '' : value)), [value])

  return (
    <select
      multiple={multiple}
      disabled={!!readOnly}
      onChange={(e) => {
        if (e.target?.value === 'false') {
          onSet('')
        } else {
          onSet(e.target?.value)
        }
      }}
      value={val}
      className={'form-select '
        + (className || 'w-100 mr-4')
        + (error ? ' error' : '')
        + (readOnly ? ' disabled' : '')
        + (!isNil(val) && !isEmpty(val?.toString()) && val !== 'false' && val ? ' selected' : '')}
      {...rest}
    >
      {loading && <option>{t('common:loading')}...</option>}
      {defaultItem && <option value={defaultItem.value}>{defaultItem.label === 'Select' ? t('common:select') : defaultItem.label}</option>}
      {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
      {groups && groups.map((group, key) => (
        <optgroup key={key as never} label={group.label}>
          {group.items?.map((item) => (
            <option
              key={item.value || item.id}
              value={item.value || item.id}
            >
              {item.label}
            </option>
          ))}
        </optgroup>
      ))}
      {items && items.map((item) => (
        <option
          key={customValueKey ? item[customValueKey] : (get(item, 'id') + get(item, 'label') + get(item, 'value'))}
          // selected={item.value === value}
          value={item[customValueKey] || (typeof item.value === 'undefined' ? item.id : item.value)}
        >
          {item[customLabelKey] || item.label}
        </option>
      ))}
    </select>
  )
}
export default React.memo(SelectInput)
