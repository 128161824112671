import React, { useMemo } from 'react'
import get from 'lodash/get'

export type CheckboxProps = {
  form ?: Record<string, any>,
  name : string,
  onChange ?: (name: string, value: boolean, childKey ?: string) => void,
  switchMode ?: boolean,
  onSet ?: (value: boolean) => void,
  value ?: boolean,
  radioValue ?: any,
  error ?: string,
  errors ?: Record<string, any>,
  labelClass ?: string,
  children ?: React.ReactNode | React.ReactNode[],
  eClass ?: string,
  label ?: string | React.ReactNode,
  onlyInput ?: boolean,
  readOnly ?: boolean,
  className ?: string,
  inputType ?: string
}

const CheckBoxInput = (props : CheckboxProps) => {
  const {
    form,
    name,
    onChange,
    switchMode,
    onSet,
    readOnly,
    value,
    error,
    radioValue,
    errors,
    inputType,
    labelClass,
    children,
    eClass,
    label,
    onlyInput,
    className
  } = props
  const checked = get(form, name) || value || false

  const inputError = useMemo(() => {
    if (errors && errors[name]) {
      return errors[name]
    }
    return error
  }, [errors, name, error])

  const onFormChange = (e:any) => {
    if (readOnly) {
      return false
    }

    if (inputType === 'radio') {
      if (onSet) {
        return onSet(e.target.value)
      }
      if (onChange) {
        onChange(name, e.target.value)
      }
    } else {
      if (onSet) {
        return onSet(e.target.checked)
      }
      if (onChange) {
        onChange(name, e.target.checked)
      }
    }
    return false
  }

  const onToggle = () => {
    if (readOnly) {
      return false
    }
    // e.preventDefault()
    if (onSet) {
      return onSet(!checked)
    }
    if (onChange) {
      onChange(name, !checked)
    }
    return false
  }

  const renderSwitchMode = useMemo(() => (
    <>
      <input
        type="checkbox"
        id={`switch${name}`}
        onChange={onFormChange}
        disabled={!!readOnly}
        checked={checked}
        className="mp-switch-input"
      />
      <label
        onClick={onToggle}
        htmlFor={`switch${name}`}
        data-on-label="Yes"
        data-off-label="No"
        className="mp-switch-input-label"
      />
    </>
  ), [name, readOnly, checked, onFormChange])

  const renderRadio = useMemo(() => (
    <div className={eClass || 'd-flex row'}>
      <input
        checked={form && `${radioValue}` === `${form[name]}`}
        type="radio"
        name={name}
        id={`radio_${name}`}
        value={radioValue}
        onClick={onFormChange}
        onChange={() => null}
        className="form-radio"
      />
      <label htmlFor={`radio_${name}`} className="form-label mb-0">{label}</label>
    </div>
  ), [eClass, form, radioValue, name, onFormChange, label])

  const renderOnlyInput = useMemo(() => (
    <>
      <input
        type="checkbox"
        onChange={onFormChange}
        disabled={!!readOnly}
        checked={checked}
        className={`form-checkbox cursor-pointer ${inputError ? ' error' : ''} ${className || ''}`}
      />
      <label onClick={onToggle} className={labelClass + ' form-label mb-0 cursor-pointer'}>{label}</label>
    </>
  ), [onFormChange, readOnly, checked, className, labelClass, label, inputError])

  const renderRegular = useMemo(() => (
    <div className={eClass || 'd-flex align-center justify-center cursor-pointer'}>
      <input
        type="checkbox"
        onChange={onFormChange}
        disabled={!!readOnly}
        checked={checked}
        className={`form-checkbox ${inputError ? ' error' : ''} ${className || ''}`}
      />
      <label onClick={onToggle} className={labelClass + ' form-label mb-0 cursor-pointer'}>{label}</label>
      {children}
    </div>
  ), [eClass, readOnly, onFormChange, checked, className, inputError, label, labelClass])

  const renderComponent = useMemo(() => {
    if (switchMode) {
      return renderSwitchMode
    }
    if (inputType === 'radio') {
      return renderRadio
    }
    if (onlyInput) {
      return renderOnlyInput
    }
    return renderRegular
  }, [switchMode, inputType, onlyInput, renderSwitchMode, renderRadio, renderOnlyInput])

  return renderComponent
}

export default CheckBoxInput
