import React from 'react'
import Link from 'next/link'

type Props = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  noShallow ?: boolean,
  href ?: string,
  children?: React.ReactNode | React.ReactNode[],
  locale ?: string,
  className ?: string
}

const GLink = ({ href, children, locale, className, ...rest } : Props) => (
  <>
    <Link
      shallow
      href={href || '/'}
      locale={locale}
      scroll={false}
      className={className}
    >
      <span {...rest}>
        {children}
      </span>
    </Link>
  </>
)

export default GLink
