import { useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { apiGuestGet } from 'mp-structure/libs/api'
import { dispatchCountryList } from 'mp-common/stores/data/action'
import i18nConfig from '../../../i18n.json'

const useCountries = () => {
  const { t } = useTranslation()
  const countries = useSelector((state: StateType) => state.data.countries)
  const dispatch = useDispatch()
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false)
  const setCountries = (list) => dispatchCountryList(list, dispatch)
  const vatCountries = useMemo(() => countries?.map((c) => ({ ...c, label: c.value })), [countries])
  const newLanguages = useMemo(() => i18nConfig?.locales?.map((x) => ({ label: t(`languages:${x}`), value: x })), [i18nConfig, t])

  const getCountries = async () => {
    if (countries?.length) {
      return
    }
    setLoadingCountries(true)
    const res = await apiGuestGet('/location/countries/1712229946.json')
    setLoadingCountries(false)
    if (res?.countries) {
      setCountries(res?.countries.map((country) => ({ label: t(`countries:${country.code}`), value: country.code })))
    }
  }

  useEffect(() => {
    getCountries()
  }, [])

  return {
    countries,
    languages: newLanguages,
    getCountries,
    loadingCountries,
    vatCountries
  }
}

export default useCountries
