import React from 'react'
import get from 'lodash/get'
import { GImage } from 'mp-common/components/form/elements'

export type RadioSelectType = {
  id ?: string | number
  value: string | number
  label: string
  color ?: string
  unitClass ?: string
  info?: string
}

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type Props = Omit<DivProps, 'onChange'> & {
  onSet ?: (value: string|number|boolean) => void,
  value?: any,
  items ?: RadioSelectType[],
  disabled ?: boolean,
  horizontal ?: boolean,
  className ?: string,
  required ?: boolean,
  isBoolean?: boolean
}

const RadioGroup = (props : Props) => {
  const {
    onSet,
    required,
    className,
    value,
    items,
    disabled,
    horizontal,
    isBoolean
  } = props

  const onFormChange = (e: any) => {
    if (onSet && e?.target?.checked) {
      if (isBoolean) {
        return onSet(e?.target?.value === 'true')
      }
      return onSet(e?.target?.value)
    }
    return false
  }

  return (
    <div className={className || `d-flex${horizontal ? ' column' : ' row'}`}>
      {items?.map((item) => (
        <div
          key={item.label + item.value + item.color}
          className={get(item, 'unitClass') ? get(item, 'unitClass') : 'mr-20 d-flex align-center gap-4'}
        >
          <input
            disabled={!!disabled}
            checked={`${value}` === `${item.value}` || `${value?.value}` === `${item.value}`}
            type="radio"
            onChange={onFormChange}
            value={item.value}
            required={required}
            className="form-radio"
          />
          <label className="form-label mb-0" style={{ color: item.color }}>
            {item.label}
          </label>
          {item?.info && (
            <span className="tm-tooltip-toggle" aria-label={item?.info} tabIndex={0}>
              <GImage src="icon-info.svg" width={20} height={20} />
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

export default React.memo(RadioGroup)
